<template>
  <InputText
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @focus="onFocus"
    :type="'text'"
    :name="'zip'"
    :class="'text-input--zip'"
    :autocomplete="'off'"
    :label="'Kod pocztowy'"
    :small="true"
    :placeholder="'54 - 000'"
    :mask="maskRef"
  />
</template>

<script>
import useIMaskZIP from "@/composables/imask/useIMaskZIP";
import useActivateIMaskPattern from "@/composables/imask/useActivateIMaskPattern";

import InputText from "./Base/InputText.vue";

export default {
  components: { InputText },

  props: {
    modelValue: String,
  },

  emits: ["update:modelValue"],

  setup() {
    const { maskRef } = useIMaskZIP();
    const { activateIMaskPattern } = useActivateIMaskPattern();

    const onFocus = () => {
      activateIMaskPattern(maskRef);
    };

    return {
      maskRef,
      onFocus,
    };
  },
};
</script>
