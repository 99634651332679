import { ref } from "vue";

export default function useIMaskZIP() {
  const maskRef = ref({
    mask: "00 - 000",
    lazy: true,
    overwrite: true,
  });

  return {
    maskRef,
  };
}
