<template>
  <InputTextZip
    :model-value="modelValue"
    @update:model-value="
      $emit('update:modelValue', $event);
      zip = $event;
    "
    @focus="isErrorZip = false"
    @blur="validateZip"
    @input="$emit('update:isValidZip', isValidZip)"
    :error="isErrorZip"
  />
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { watch } from "vue";

import InputTextZip from "@/components/UI/InputTextZip.vue";

export default {
  components: { InputTextZip },

  props: {
    modelValue: String,
  },

  emits: ["update:modelValue", "update:isValidZip"],

  setup(props, { emit }) {
    const zip = ref(props.modelValue);

    // Validation on emptyness
    const isErrorZip = ref(false);
    const validateZip = () => {
      isErrorZip.value = zip.value.includes("_") || !zip.value;
    };
    const isValidZip = computed(() => {
      return !zip.value.includes("_") && !!zip.value && zip.value.length > 7;
    });

    watch(
      isValidZip,
      (newIsValid) => {
        emit("update:isValidZip", newIsValid);
      },
      { immediate: true }
    );

    return {
      zip,
      isErrorZip,
      validateZip,
      isValidZip,
    };
  },
};
</script>
