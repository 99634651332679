<template>
  <InputTextNameFirst
    v-model="nameFirst"
    @focus="isErrorNameFirst = false"
    @blur="validateNameFirst()"
    :error="isErrorNameFirst"
  />
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { watch } from "vue";

import InputTextNameFirst from "@/components/UI/InputTextNameFirst.vue";

export default {
  components: { InputTextNameFirst },

  props: {
    modelValue: String,
  },

  emits: ["update:modelValue", "update:isValidNameFirst"],

  setup(props, { emit }) {
    const nameFirst = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (newName) => {
        nameFirst.value = newName;
      }
    );

    watch(nameFirst, (newName) => {
      emit("update:modelValue", newName);
    });

    // Validation
    const isErrorNameFirst = ref(false);
    const validateNameFirst = () => {
      // Is empty?
      isErrorNameFirst.value = !isValidNameFirst.value;
    };

    const isValidNameFirst = computed(() => {
      if (nameFirst.value === null) return false;
      return nameFirst.value.length > 1;
    });

    watch(
      isValidNameFirst,
      (newIsValid) => {
        emit("update:isValidNameFirst", newIsValid);
      },
      { immediate: true }
    );

    return {
      nameFirst,
      isErrorNameFirst,
      validateNameFirst,
      isValidNameFirst,
    };
  },
};
</script>

<style>
/* For styles loook into InputText < style > block */
</style>
