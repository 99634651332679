<template>
  <LayoutViewWrapperNarrow class="content">
    <BaseH1 :text="'Rejestracja'" :short="true" />

    <component :is="FormComponent" @signup="signup" />
  </LayoutViewWrapperNarrow>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { computed } from "@vue/reactivity";
import { useUserStore } from "@/stores/user";
import { useUserProfileStore } from "@/stores/userProfile";
import { useRoute, useRouter } from "vue-router";

import {
  ROUTE_REGISTRATION_NAME,
  ROUTE_REGISTRATION_WELCOME_NAME,
} from "@/router/constants";

import useGoogleMapsGeocoder from "@/composables/useGoogleMapsGeocoder";
import useAuth from "@/composables/useAuth";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputTextNameFirstValidation from "@/components/UI/InputTextNameFirstValidation";
import InputTextZipValidation from "@/components/UI/InputTextZipValidation.vue";
import ButtonPrimaryDone from "@/components/UI/ButtonPrimaryDone.vue";
import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow.vue";

export default {
  components: {
    BaseH1,
    InputTextNameFirstValidation,
    InputTextZipValidation,
    ButtonPrimaryDone,
    LayoutViewWrapperNarrow,
  },

  setup() {
    const userStore = useUserStore();
    const userProfileStore = useUserProfileStore();
    const router = useRouter();
    const route = useRoute();
    const { zipToCoordsLatLng } = useGoogleMapsGeocoder();
    const { handleRegistration, getAuthStrategy } = useAuth();

    const userType = route.query.userType;
    const authStrategy = getAuthStrategy();

    if (!userType || !authStrategy) {
      // Redirect to the start of registration process, if no userType in the URI query
      router.push({ name: ROUTE_REGISTRATION_NAME });
    }

    const nextRoute = {
      name: ROUTE_REGISTRATION_WELCOME_NAME,
    };

    const FormComponent = computed(() => {
      if (userType === "client") {
        return defineAsyncComponent(() =>
          import(
            "@/components/Auth/Registration/AuthRegistrationDetailsFormClient.vue"
          )
        );
      }

      if (userType === "contractor") {
        return defineAsyncComponent(() =>
          import(
            "@/components/Auth/Registration/AuthRegistrationDetailsFormContractor.vue"
          )
        );
      }

      return null;
    });

    const signup = async () => {
      try {
        await _setInitialUserState();
        await handleRegistration();
        router.push(nextRoute);
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    const _setInitialUserState = async () => {
      userStore.role = "viewer";
      userStore.userType = userType;
      userStore.accountType = "sole";
      await _setUserZipLocation(userProfileStore.zip);
    };

    const _setUserZipLocation = async (zip) => {
      console.log("START >> SET_ZIP_LOCATION_POINT");
      const zipCoordsLatLng = await zipToCoordsLatLng(zip);

      if (zipCoordsLatLng === null) {
        // If coords were not found --> set null and return
        userProfileStore.zipLocationPoint = null;
        console.log("FAIL >> SET_ZIP_LOCATION_POINT");
        return;
      }

      const zipCoordsLngLat = zipCoordsLatLng.slice().reverse();

      userProfileStore.zipLocationPoint = {
        coordinates: zipCoordsLngLat,
        type: "Point",
      };
      console.log("END >> SET_ZIP_LOCATION_POINT");
    };

    return {
      signup,
      FormComponent,
    };
  },
};
</script>

<style scoped>
.content {
  justify-content: flex-start;
  row-gap: 60px;
}

@media (max-width: 1200px) {
  .content {
    row-gap: 80px;
  }
}
</style>
